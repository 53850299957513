import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import { saveAs } from 'file-saver';

import Doc from '../../documents/Form_ADV_Part_2A_Brochure_April_2023.pdf';
import Layout from '../../components/Layout';
import MetaData from '../../components/meta/MetaData';
import { Body, CrowdLink } from '../../styles';

const Download = ({ location }) => {
	useEffect(() => {
		saveAs(Doc, 'Form_ADV_Part_2A_Brochure_April_2023.pdf');
	}, []);

	const downloadFile = () => {
		saveAs(Doc, 'Form_ADV_Part_2A_Brochure_April_2023.pdf');
	};

	return (
		<Layout>
			<MetaData
				title="Envel - ADV Brochure April 2023"
				description="ADV Brochure April 2023"
				image="images/social/social.png"
				location={location}
			/>

			<div style={{ height: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<Body center style={{ width: '90%' }}>
					Thank you for downloading the ADV Brochure April 2023. If it did not download automatically, please click{' '}
					<CrowdLink onClick={downloadFile}>here</CrowdLink>
					.
					<br />
					<br /> Return <Link to="/">home.</Link>
				</Body>
			</div>
		</Layout>
	);
};

export default Download;
